import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout/Layout';
import PageContent from '../../components/PageContent/PageContent';
import { Typography } from '../../components/Typography/Typography';
import * as SC from './styles';
import Icon, { IconName } from '../../components/Icon/Icon';
import { useColors } from '../../hooks/theme';
import { useDispatch, useSelector } from 'react-redux';
import { ICompanyState } from '../../redux/company/company.types';
import { IDashboardState } from '../../redux/dashboard/dashboard.types';
import Card from '../../components/Card/Card';
import { useEffect } from 'react';
import { initDashboard, setFilterDate } from '../../redux/dashboard/dashboard.redux';
import { IAuthUserState } from '../../redux/authUser/authUser.types';
import { EDateFilter } from '../../common/types';
import Select, { ISelectOption } from '../../components/Select/Select';
import { usePermission } from '../../hooks/usePermission';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { call } from 'redux-saga/effects';
import DashboardCallReasons from '../../components/DashboardCallReasons/DashboardCallReasons';

export default function DashboardPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const colors = useColors();
  const { id: companyId } = useSelector((state: { company: ICompanyState }) => state.company.company);
  const { user } = useSelector((state: { authUser: IAuthUserState }) => state.authUser);
  const { dateFilter, chatOpen, activeSessions, askedQuestionsCount, topAskedQuestions, calls, callReasons, callReasonsLoading } =
    useSelector((state: { dashboard: IDashboardState }) => state.dashboard);
  const { isAdmin } = usePermission();
  const hasData =
    !!askedQuestionsCount || !!chatOpen.shown || !!chatOpen.opened || !!calls.successEvaluation?.total || !!callReasons.length;
  const options: ISelectOption<EDateFilter>[] = Object.values(EDateFilter).map(item => ({
    label: t(`chats.filterCalendar-${item}`),
    value: item,
  }));

  function handleSelectChange(_: string, value: EDateFilter) {
    dispatch(setFilterDate(value));
  }

  useEffect(() => {
    if (companyId && isAdmin) {
      dispatch(initDashboard());
    }
  }, [companyId, dateFilter]);

  if (!user.uid) {
    return null;
  }

  return (
    <Layout>
      <PageContent
        title={t('dashboard.title')}
        hasData={hasData}
        emptyTitle={t('dashboard.emptyTitle')}
        emptyIcon={IconName.ChartBar}
        emptyDescription={t('dashboard.emptyDesc')}
        actions={
          isAdmin && <Select name="dashboardCalendar" value={dateFilter} options={options} onChange={handleSelectChange} position="right" />
        }
      >
        <SC.Wrapper>
          {/* {isAdmin && (
            <Card padding="medium">
              <Icon
                name={IconName.Messages}
                color={colors.brand.default}
                width={2.4}
                height={2.4}
                backgroundHeight={5}
                backgroundWidth={5}
                backgroundColor={colors.brand.lightness}
                withBackground
              />
              <SC.CardText>
                <Typography variant="h1" bold>
                  {activeSessions}
                </Typography>
                <Typography variant="caption" bold>
                  {t('dashboard.activeChatsTitle')}
                </Typography>
              </SC.CardText>
            </Card>
          )} */}
          {isAdmin && (
            <>
              <SC.QuestionsCountWrapper>
                <Card padding="medium">
                  <Icon
                    name={IconName.QA}
                    color={colors.brand.default}
                    width={2.4}
                    height={2.4}
                    backgroundHeight={5}
                    backgroundWidth={5}
                    backgroundColor={colors.brand.lightness}
                    withBackground
                  />
                  <SC.CardText>
                    <Typography variant="h1" bold>
                      {askedQuestionsCount}
                    </Typography>
                    <Typography variant="caption" bold>
                      {t('dashboard.askedQuestionCount')}
                    </Typography>
                  </SC.CardText>
                </Card>
              </SC.QuestionsCountWrapper>
              <SC.ChatOpenWrapper>
                <Card padding="medium">
                  <Icon
                    name={IconName.ChatAlt2}
                    color={colors.brand.default}
                    width={2.4}
                    height={2.4}
                    backgroundHeight={5}
                    backgroundWidth={5}
                    backgroundColor={colors.brand.lightness}
                    withBackground
                  />
                  <SC.CardRow>
                    <SC.CardText>
                      <Typography variant="h1" bold>
                        {chatOpen.shown}
                      </Typography>
                      <Typography variant="caption" bold>
                        {t('dashboard.chatShowCount')}
                      </Typography>
                    </SC.CardText>
                    <SC.CardText>
                      <Typography variant="h1" bold>
                        {chatOpen.opened}
                      </Typography>
                      <Typography variant="caption" bold>
                        {t('dashboard.chatOpenedCount')}
                      </Typography>
                    </SC.CardText>
                  </SC.CardRow>
                </Card>
              </SC.ChatOpenWrapper>
              <SC.CallsSuccessWrapper>
                <Card padding="medium">
                  <Icon
                    name={IconName.Phone}
                    color={colors.brand.default}
                    width={2.4}
                    height={2.4}
                    backgroundHeight={5}
                    backgroundWidth={5}
                    backgroundColor={colors.brand.lightness}
                    withBackground
                  />
                  <SC.CardRow>
                    <SC.CardText>
                      <Typography variant="h1" bold>
                        {calls.successEvaluation?.total}
                      </Typography>
                      <Typography variant="caption" bold>
                        {t('dashboard.callsTotalCount')}
                      </Typography>
                    </SC.CardText>
                    <SC.CardText>
                      <Typography variant="h1" bold>
                        {calls.successEvaluation?.succeed}
                      </Typography>
                      <Typography variant="caption" bold>
                        {t('dashboard.callsSucceedCount')}
                      </Typography>
                    </SC.CardText>
                  </SC.CardRow>
                </Card>
              </SC.CallsSuccessWrapper>
              {!!calls.departments?.length && (
                <SC.CallsDepartmentWrapper>
                  <Card padding="medium">
                    <Typography variant="h1" bold>
                      {t('dashboard.callDepartments')}
                    </Typography>
                    <SC.CardRow>
                      {calls.departments.map(department => (
                        <SC.CardText key={department.name}>
                          <Typography variant="h1" bold>
                            {department.count}
                          </Typography>
                          <Typography variant="caption" bold>
                            {department.name}
                          </Typography>
                        </SC.CardText>
                      ))}
                    </SC.CardRow>
                  </Card>
                </SC.CallsDepartmentWrapper>
              )}
              {!!calls.directions && (
                <SC.CallsDirectionsWrapper>
                  <Card padding="medium">
                    <Typography variant="h1" bold>
                      {t('dashboard.callDirections')}
                    </Typography>
                    <SC.CardRow>
                      <SC.CardText>
                        <Typography variant="h1" bold>
                          {calls.directions.inbound}
                        </Typography>
                        <Typography variant="caption" bold>
                          {t('dashboard.callDirectionsInbound')}
                        </Typography>
                      </SC.CardText>
                      <SC.CardText>
                        <Typography variant="h1" bold>
                          {calls.directions.outbound}
                        </Typography>
                        <Typography variant="caption" bold>
                          {t('dashboard.callDirectionsOutbound')}
                        </Typography>
                      </SC.CardText>
                    </SC.CardRow>
                  </Card>
                </SC.CallsDirectionsWrapper>
              )}
              {!!calls.handlers && (
                <SC.CallsHandlersWrapper>
                  <Card padding="medium">
                    <Typography variant="h1" bold>
                      {t('dashboard.callHandlers')}
                    </Typography>
                    <SC.CardRow>
                      <SC.CardText>
                        <Typography variant="h1" bold color="brand">
                          {calls.handlers.ai}
                        </Typography>
                        <Typography variant="caption" bold color="brand">
                          {t('dashboard.callHandlersAi')}
                        </Typography>
                      </SC.CardText>
                      <SC.CardText>
                        <Typography variant="h1" bold>
                          {calls.handlers.human}
                        </Typography>
                        <Typography variant="caption" bold>
                          {t('dashboard.callHandlersHuman')}
                        </Typography>
                      </SC.CardText>
                    </SC.CardRow>
                  </Card>
                </SC.CallsHandlersWrapper>
              )}
              {/* <SC.CallReasonsWrapper>
                <DashboardCallReasons isLoading={callReasonsLoading} categories={callReasons} />
              </SC.CallReasonsWrapper> */}
              <SC.TopQuestionsCountWrapper>
                <Card padding="medium">
                  <Typography variant="h1" bold>
                    {t('dashboard.topAskedQuestion')}
                  </Typography>
                  <SC.TopQuestions>
                    {topAskedQuestions.length ? (
                      topAskedQuestions.map(question => (
                        <SC.TopQuestion key={`${question.question}-${question.percent}`} $width={question.percent}>
                          <Typography variant="label" bold>
                            {question.percent}%
                          </Typography>
                          <Typography variant="caption" color="body">
                            {question.question}
                          </Typography>
                        </SC.TopQuestion>
                      ))
                    ) : (
                      <EmptyContent title={t('dashboard.topAskedQuestionEmptyTitle')} />
                    )}
                  </SC.TopQuestions>
                </Card>
              </SC.TopQuestionsCountWrapper>
            </>
          )}
        </SC.Wrapper>
      </PageContent>
    </Layout>
  );
}
